import MenuBtn from "components/common/MenuBtn";
import {
  ArrowBox,
  CoachContainer,
  CongratulationBody,
  CongratulationBottom,
  CongratulationHeader,
  MoreFeatureAnimation,
} from "./Index.style";
import DefaultBtn from "components/common/DefaultBtn";
import { Arrow } from "constants/icons/coach";
import Pointed from "./Pointed";
import { css } from "styled-components";
import { CongratulationCoachProps } from "types/common";
import { useTranslation } from "react-i18next";

const CongratulationCoach = ({ coachHandler, experience }: CongratulationCoachProps) => {
  const { t } = useTranslation();

  return (
    <CoachContainer onClick={coachHandler}>
      <CongratulationHeader>
        <MenuBtn isCoach={true} />
      </CongratulationHeader>
      <CongratulationBody $isOled={experience === "oled"}>
        <DefaultCoach />
      </CongratulationBody>
      <CongratulationBottom>
        <div>{t("coach_congratulation_default_bottom")}</div>
        <ArrowBox>
          <Arrow />
          <Arrow />
        </ArrowBox>
        <Pointed
          positionCss={css`
            position: absolute;
            top: 22px;
            left: calc(50vw - 20px);
            animation: ${MoreFeatureAnimation} 3s ease infinite;
          `}
        />
      </CongratulationBottom>
    </CoachContainer>
  );
};

export default CongratulationCoach;

const DefaultCoach = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div>{t("coach_congratulation_default_guide")}</div>
      <DefaultBtn handler={() => {}}>{t("common_play_again")}</DefaultBtn>
    </div>
  );
};
