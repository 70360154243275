import { Outlet } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Header from "components/common/Header";
import BNB from "components/common/BNB";
import RotatePhone from "components/common/RotatePhone";
import ActiveMonitor from "components/ActiveMonitor";

import { useFullScreen } from "core/hooks/useFullScreen";
import { useLandScape } from "core/hooks/useLandScape";

import { useTheme } from "core/contexts/ThemeContext";
import { Container, ContentContainer } from "./index.style";
import { useSocket } from "core/contexts/SocketContext";
import { useEffect, useState } from "react";
import { useUrlQuery } from "core/hooks/useUrlQuery";
import { EVENTS } from "constants/enum/events";
import useOnlineLink from "core/hooks/useOnlineLink";
import { useRecoilValue } from "recoil";
import { tvEnvState } from "core/store/tvEnvState";
import DefaultBackground from "components/common/DefaultBackground/DefaultBg";
import PictureBackground from "components/common/DefaultBackground/PictureBg";
import SoundBackground from "components/common/DefaultBackground/SoundBg";
import { stepState } from "core/store/stepState";
import { STEP } from "constants/enum/step";
import MainSoundBackground from "components/common/DefaultBackground/MainSoundBg";
import AiOptimizationBackground from "components/common/DefaultBackground/AiOptimizationBg";

const ExperienceLayout = () => {
  const isLandscape = useLandScape();
  const { element, triggerFull } = useFullScreen();
  const { theme } = useTheme();

  const query = useUrlQuery(["duid", "deviceId"] as const);
  const step = useRecoilValue(stepState);
  const { SCENARIO, SOCKET_URL } = useRecoilValue(tvEnvState);
  const { socket, isConnected } = useSocket();
  const [isJoined, setIsJoined] = useState(false);
  const [isSNBOpen, setIsSNBOpen] = useState<boolean | null>(null);

  useEffect(() => {
    if (!isConnected) return;
    socket.emit(
      EVENTS.JOIN_ROOM,
      {
        duid: query.duid,
        deviceId: query.deviceId,
      },
      (_isJoined: boolean) => {
        setIsJoined(_isJoined);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  useEffect(() => {
    if (!isConnected && SOCKET_URL) {
      socket.connect();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SOCKET_URL]);

  useOnlineLink();

  const background = () => {
    switch (SCENARIO) {
      case "oled":
        return <DefaultBackground />;
      case "sound":
        return <SoundBackground />;
      case "picture4k":
      case "picture8k":
        return <PictureBackground />;
      case "smartthings":
        return <DefaultBackground />;
      case "ai_qr":
        if (step.step === STEP.OTSPLUS || step.step === STEP.AVA) {
          return <MainSoundBackground />;
        } else if (step.step === STEP.AI_TV_UNVEILED) {
          return <PictureBackground />;
        } else {
          return <AiOptimizationBackground />;
        }
    }
  };

  return (
    <ThemeProvider theme={theme.color}>
      <Container
        ref={element}
        onClick={(e) => {
          e.preventDefault();
          triggerFull();
        }}
      >
        {isJoined ? (
          <>
            <ActiveMonitor>
              <Header isSNBOpen={isSNBOpen} setIsSNBOpen={setIsSNBOpen} />
              <ContentContainer>
                <Outlet />
              </ContentContainer>
              <BNB setIsSNBOpen={setIsSNBOpen} />
              {isLandscape && <RotatePhone />}
              {background()}
            </ActiveMonitor>
          </>
        ) : (
          <>
            <Header isSNBOpen={isSNBOpen} setIsSNBOpen={setIsSNBOpen} />
            <ContentContainer>
              <Outlet />
            </ContentContainer>
            <BNB setIsSNBOpen={setIsSNBOpen} />
            {isLandscape && <RotatePhone />}
            {background()}
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default ExperienceLayout;
