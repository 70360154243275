import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "containers/Register";
import NotFound from "containers/NotFound";
import ExperienceLayout from "layout/ExperienceLayout";
import Complete from "containers/Complete";
import Main from "containers/Main";

const MRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ExperienceLayout />}>
          <Route path="/main">
            <Route index element={<Main />} />
            <Route path="*" element={<NotFound title={"main"} />} />
          </Route>
        </Route>
        <Route path="/register">
          <Route index element={<Register />} />
          <Route path="*" element={<NotFound title={"register"} />} />
        </Route>
        <Route path="/complete">
          <Route index element={<Complete />} />
          <Route path="*" element={<NotFound title={"Complete"} />} />
        </Route>
        {/* 빈 페이지 */}
        <Route path="*" element={<NotFound title={"root"} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MRouter;
