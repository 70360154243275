import { useCallback, useEffect, useState } from "react";
import { Container, PictureBackgroundContainer } from "./index.style";
import { useTheme } from "core/contexts/ThemeContext";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const PictureBackground = () => {
  const { theme } = useTheme();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [xList, setXList] = useState<number[]>([]);
  const [dotList, setDotList] = useState<number[]>([]);

  const getVerticalBarList = useCallback(() => {
    const startX = 14;
    const tempList = [];
    for (let i = startX; i < windowDimensions.width; i += 33) {
      tempList.push(i);
    }
    setXList(tempList);
  }, [windowDimensions.width]);

  const getDotList = useCallback(() => {
    const startY = 20;
    const tempList = [];
    for (let i = startY; i < windowDimensions.height; i += 45) {
      tempList.push(i);
    }
    setDotList(tempList);
  }, [windowDimensions.height]);

  useEffect(() => {
    getVerticalBarList();
  }, [getVerticalBarList]);

  useEffect(() => {
    getDotList();
  }, [getDotList]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const DotBox = useCallback(({ y }: { y: number }) => {
    return (
      <g transform={`translate(0 ${y})`}>
        <circle cx="0" cy="0" r="2" className={`spotClass${getRandomInt(1, 10)}`}></circle>
      </g>
    );
  }, []);

  const VerticalBar = useCallback(
    ({ x }: { x: number }) => {
      return (
        <g transform={`translate(${x} 0)`} style={{ mixBlendMode: "overlay", isolation: "isolate" }}>
          {dotList.map((y) => (
            <DotBox y={y} />
          ))}
        </g>
      );
    },
    [dotList, DotBox]
  );

  return (
    <PictureBackgroundContainer $bgLinear={theme.background.baseBg}>
      <Container>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${windowDimensions.width} ${windowDimensions.height}`}
          style={{ marginTop: "100px" }}
        >
          <defs data-v-79a988b3="">
            <clipPath id="clip-_1.Picture_01_1">
              <rect width={windowDimensions.width} height={windowDimensions.height}></rect>
            </clipPath>
          </defs>
          <g id="_1.Picture_01_1" data-name="1.Picture_01_1" clipPath="url(#clip-_1.Picture_01_1)">
            <rect
              width={windowDimensions.width}
              height={windowDimensions.height}
              fill="url(#linear-gradient)"
              opacity="0.6"
            ></rect>
            {xList.map((x, i) => (
              <VerticalBar x={x} key={i} />
            ))}
          </g>
        </svg>
      </Container>
    </PictureBackgroundContainer>
  );
};

export default PictureBackground;
