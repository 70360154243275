import { useEffect, useState } from "react";

import { useSocket } from "core/contexts/SocketContext";
import { useTheme } from "core/contexts/ThemeContext";
import { useFunnel } from "core/hooks/useFunnel";

import StartLayout from "layout/StartLayout";
import CoverLayout from "layout/CoverLayout";

import WelcomeSoundBg from "assets/welcome/bg_sound.png";

import { EVENTS } from "constants/enum/events";
import { scenarioTheme } from "constants/scenarioTheme";
import { Container } from "constants/theme/globalStyles";
import { COMMANDS } from "constants/enum/commands";
import CompareModal from "layout/Modal/CompareModal";
import { STEP } from "constants/enum/step";
import { useRecoilState } from "recoil";
import { stepState } from "core/store/stepState";
import useTimeout from "core/hooks/useTimer";
import { SCENARIO_STEP } from "constants/enum/scenarioStep";
import CongratulationLayout from "layout/CongratulationLayout";
import ExperienceCoach from "layout/CoachLayout/ExperienceCoach";
import CongratulationCoach from "layout/CoachLayout/CongratulationCoach";
import { useUrlQuery } from "core/hooks/useUrlQuery";
import { postMobileStartExp } from "core/util/api/tvApi";
import OtsProModal from "layout/Modal/OtsProModal";
import { AVAExpereince } from "./avaExperience";
import { AITvUnveiledExperience } from "./AITvUnveiledExperience";

const Main = () => {
  const [step, setStepState] = useRecoilState(stepState);

  const [playTime, setPlayTime] = useState<number>(-1);

  const { theme, setTheme } = useTheme();
  const { clear, setAsync } = useTimeout();
  const { socket, isConnected, scenarioStep, scenarioSkip, scenarioBridge, scenarioExperience } = useSocket();
  const [Funnel, setStep] = useFunnel([...theme.StepList] as const, STEP.COVER);
  const [ExperienceFunnel, setExperienceStep] = useFunnel([...theme.ExperienceStepList] as const, SCENARIO_STEP.START);
  const [isCoach, setIsCoach] = useState<boolean>(false);

  const query = useUrlQuery(["duid"] as const);

  const coachOpen = () => setIsCoach(true);

  const coachClose = () => setIsCoach(false);

  const startSkipHandler = (step: STEP) => {
    setPlayTime(-1);
    setStepState({ step, scenarioStep: SCENARIO_STEP.SKIP });
    scenarioSkip(step);
  };

  const onlyScreenSkip = (step: STEP) => {
    setPlayTime(-1);
    setStepState({ step, scenarioStep: SCENARIO_STEP.SKIP });
  };

  const goBridge = (step: STEP) => {
    setStepState({ step, scenarioStep: SCENARIO_STEP.BRIDGE });
    scenarioBridge(step);
    setPlayTime(-1);
  };

  const goExperience = (step: STEP) => {
    setStepState((prev) => ({
      ...prev,
      scenarioStep: SCENARIO_STEP.EXPERIENCE,
    }));
    scenarioExperience(step);
    setPlayTime(-1);
  };

  const nextExperience = async (step: STEP) => {
    await setAsync(500);
    scenarioStep(step);
    setStepState({ step, scenarioStep: SCENARIO_STEP.START });
  };

  /// 스텝으로 이동  ------------------------------------------------------------
  useEffect(() => {
    console.log(step);
    clear();
    setPlayTime(-1);
    setStep(step.step as any);
    setExperienceStep(step.scenarioStep as any);

    if (step.step === STEP.OUTRO || step.scenarioStep === SCENARIO_STEP.SKIP) {
      coachOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    const socketOn = async (data: any) => {
      switch (data.command) {
        case COMMANDS.SCENARIO_START:
          setPlayTime(parseInt(`${data.duration / 1000}`));
          break;
        case COMMANDS.SCENARIO_BRIDGE:
          await setAsync(data.duration);
          setPlayTime(-1);
          if (step.step === STEP.AI_TV_UNVEILED) {
            nextExperience(theme.StepList[theme.StepList.indexOf(STEP.AI_TV_UNVEILED) + 1]);
          } else {
            setStepState((prev) => ({
              ...prev,
              scenarioStep: SCENARIO_STEP.EXPERIENCE,
            }));
            scenarioExperience(data.step);
          }
          break;
        case COMMANDS.EXPERIENCE_DATA:
          if (data.step === STEP.AI_TV_UNVEILED) {
            nextExperience(theme.StepList[theme.StepList.indexOf(STEP.AI_TV_UNVEILED) + 1]);
          } else if (data.step === STEP.AVA) {
            nextExperience(theme.StepList[theme.StepList.indexOf(STEP.AVA) + 1]);
          }
          break;
        default:
          break;
      }
    };

    if (!socket.hasListeners(EVENTS.SCENARIO_TV_TO_MOBILE)) {
      socket.on(EVENTS.SCENARIO_TV_TO_MOBILE, socketOn);
    }

    return () => {
      socket.off(EVENTS.SCENARIO_TV_TO_MOBILE, socketOn);
      setStepState({ step: STEP.COVER, scenarioStep: SCENARIO_STEP.START });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  useEffect(() => {
    if (step.step === STEP.OTSPLUS || step.step === STEP.AVA) {
      //TODO : STEP에 AI Optimization QR 스탭 추가해서 해당 체험 내용만 추가
      setTheme((prev) => ({
        ...prev,
        CoverLayout: {
          image: WelcomeSoundBg,
          title: "ai_qr_cover",
        },
      }));
    } else {
      setTheme(scenarioTheme.ai_qr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step.step]);

  return (
    <Container>
      <Funnel>
        {theme.StepList.map((step, index) => {
          const layoutInfo = (theme.StartLayouts as any)[step];
          return (
            <Funnel.Step name={step} key={index}>
              {step === STEP.COVER ? (
                <CoverLayout
                  startHandler={() => {
                    postMobileStartExp(query.duid, "aiqr");
                    nextExperience(theme.StepList[index + 1]);
                  }}
                />
              ) : step === STEP.OUTRO ? (
                <>
                  {isCoach && (
                    <CongratulationCoach
                      coachHandler={() => {
                        setIsCoach(false);
                      }}
                      experience="picture"
                    />
                  )}
                  <CongratulationLayout experience={"ai_qr"} />
                </>
              ) : (
                <ExperienceFunnel>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.START}>
                    {step === STEP.AVA ? (
                      <StartLayout
                        layoutInfo={layoutInfo}
                        isExperience={true}
                        time={playTime}
                        buttonHandler={() => {
                          // onlySendSkip(step);
                          goExperience(step);
                        }}
                        endTimerHandler={() => {
                          // onlySendSkip(step);
                          goExperience(step);
                        }}
                      />
                    ) : step === STEP.AI_OPTIMIZATION ? (
                      <StartLayout
                        layoutInfo={layoutInfo}
                        isExperience={true}
                        time={playTime}
                        buttonHandler={() => {
                          // startSkipHandler(step);
                          goExperience(step);
                        }}
                        endTimerHandler={() => {
                          // startSkipHandler(step);
                          goExperience(step);
                        }}
                      />
                    ) : step === STEP.AI_TV_UNVEILED ? (
                      <StartLayout
                        layoutInfo={layoutInfo}
                        isSkip={true}
                        time={playTime}
                        buttonHandler={() => startSkipHandler(step)}
                        endTimerHandler={() => onlyScreenSkip(step)}
                      />
                    ) : (
                      <StartLayout
                        layoutInfo={layoutInfo}
                        isSkip={true}
                        time={playTime}
                        buttonHandler={() => startSkipHandler(step)}
                        endTimerHandler={() => startSkipHandler(step)}
                      />
                    )}
                  </ExperienceFunnel.Step>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.SKIP}>
                    {isCoach && <ExperienceCoach coachHandler={coachClose} />}
                    {step === STEP.AI_TV_UNVEILED ? (
                      <StartLayout
                        layoutInfo={layoutInfo}
                        isSkip={false}
                        isExperience={false}
                        time={playTime}
                        buttonHandler={() => goExperience(step)}
                      />
                    ) : (
                      <>
                        {isCoach && <ExperienceCoach coachHandler={coachClose} />}
                        <StartLayout
                          layoutInfo={layoutInfo}
                          isSkip={false}
                          isExperience={playTime > 0}
                          timeBarEnable={false}
                          time={playTime}
                          buttonHandler={() => goBridge(step)}
                        />
                      </>
                    )}
                  </ExperienceFunnel.Step>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.BRIDGE}>
                    <StartLayout layoutInfo={layoutInfo} isSkip={false} isExperience={true} timeBarEnable={false} />
                  </ExperienceFunnel.Step>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.EXPERIENCE}>
                    {step === STEP.AI_TV_UNVEILED ? (
                      <AITvUnveiledExperience
                        key={step}
                        modalCloseHandler={() => nextExperience(theme.StepList[index + 1])}
                        startLayouts={layoutInfo}
                      />
                    ) : step === STEP.AVA ? (
                      <AVAExpereince
                        key={step}
                        layoutInfo={layoutInfo}
                        nextExp={() => nextExperience(theme.StepList[index + 1])}
                      />
                    ) : step === STEP.OTSPLUS ? (
                      <OtsProModal
                        key={step}
                        isModalOpen={true}
                        modalCloseHandler={() => {
                          coachClose();
                          nextExperience(theme.StepList[index + 1]);
                        }}
                      />
                    ) : (
                      step === STEP.AI_OPTIMIZATION && (
                        <CompareModal
                          key={step}
                          isModalOpen={true}
                          modalCloseHandler={() => {
                            nextExperience(theme.StepList[index + 1]);
                          }}
                          startLayouts={layoutInfo}
                        />
                      )
                    )}
                  </ExperienceFunnel.Step>
                </ExperienceFunnel>
              )}
            </Funnel.Step>
          );
        })}
      </Funnel>
    </Container>
  );
};

export default Main;
