import { getPdpBySubsidiary, getRegion, getTvsByDUID } from "core/util/api/tvApi";
import { tvEnvState } from "core/store/tvEnvState";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { experienceTvType } from "types/common";
import { DEFAULT_PDP } from "core/store/linkState";

export default function useTvEnv() {
  const setTvEnv = useSetRecoilState(tvEnvState);
  const path = window.location;

  const getUrlInfo = () => {
    if (path.pathname.includes("offlineQr")) {
      const point = path.search.indexOf("=");
      return {
        DUID: path.search.slice(point + 1, path.search.length),
        SUBSIDIARY: "",
        SCENARIO: "offlineQr",
      };
    } else {
      const infoInnerPath = path.search.slice(1, window.location.search.length).split("&");
      return {
        DUID: infoInnerPath[0].split("=")[1],
        SUBSIDIARY: infoInnerPath[1]?.split("=")[1],
        SCENARIO: path.pathname.replace("/", ""),
      };
    }
  };

  const getTvInfo = async () => {
    const { DUID, SCENARIO, SUBSIDIARY } = getUrlInfo();
    const {
      isSuccess,
      data: { id, deviceModel, subsidiaryCode, retailer, pdp, storeName, tvName },
    } = await getTvsByDUID(DUID);

    if (isSuccess) {
      const {
        data: { socketAddr },
      } = await getRegion(subsidiaryCode);

      setTvEnv({
        SUBSIDIARY: subsidiaryCode,
        DUID: DUID,
        DEVICE_ID: id,
        DEVICE_MODEL: deviceModel,
        RETAILER: retailer,
        PDP: pdp ?? DEFAULT_PDP,
        STORE: storeName,
        TVNAME: tvName,
        SCENARIO: SCENARIO === 'main' ? 'ai_qr' : SCENARIO as experienceTvType,
        SOCKET_URL: socketAddr,
      });
    } else {
      const {
        data: { pdpLink },
      } = await getPdpBySubsidiary(SUBSIDIARY);
      const {
        data: { socketAddr },
      } = await getRegion(SUBSIDIARY);

      setTvEnv((prev) => {
        return {
          ...prev,
          PDP: pdpLink,
          SCENARIO: SCENARIO === 'main' ? 'ai_qr' : SCENARIO as experienceTvType,
          SOCKET_URL: socketAddr,
        };
      });
    }
  };

  useEffect(() => {
    getTvInfo();
  }, []);

  return { getUrlInfo, getTvInfo };
}
