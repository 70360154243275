import { useRef } from "react";

export const useFullScreen = () => {
  const element = useRef<HTMLDivElement>(null);
  const triggerFull = async () => {
    if (element.current) {
      if (element.current.requestFullscreen) await element.current.requestFullscreen();
      else if ((element.current as any).mozRequestFullScreen) await (element.current as any).mozRequestFullScreen();
      else if ((element.current as any).webkitRequestFullscreen)
        await (element.current as any).webkitRequestFullScreen();
      else if ((element.current as any).msRequestFullscreen) await (element.current as any).msRequestFullscreen();
    }
  };
  const exitFull = async () => {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    }
  };

  return { element, triggerFull, exitFull };
};
