import { getLanguage } from "core/util/api/tvApi";
import useTvEnv from "core/hooks/useTvEnv";
import { tvEnvState } from "core/store/tvEnvState";
import { FunctionComponent, ReactNode, createContext, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

interface ILanguageContext {
  languageJson: Object;
  initLanguage: () => Promise<void>;
}

export const LanguageContext = createContext<any>({});

export const LanguageProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const tvEnv = useRecoilValue(tvEnvState);
  const langJson = useRef({});

  const { i18n } = useTranslation();
  const { t } = useTranslation();

  useTvEnv();

  const setLocalization = async () => {
    const { isSuccess: isGetLanguageSuccess, data: languageApi } = await getLanguage("2024 AI QR", tvEnv.SUBSIDIARY);

    const languageJson = isGetLanguageSuccess && languageApi;

    if (!languageJson) {
      throw new Error(t("set_no_resource_language"));
    }

    langJson.current = { ...languageJson };
  };

  const initLanguage = async () => {
    await setLocalization();
    i18n.addResourceBundle(tvEnv.SUBSIDIARY, "translation", langJson.current);
    i18n.changeLanguage(tvEnv.SUBSIDIARY);
  };

  useEffect(() => {
    if (tvEnv.SUBSIDIARY.length !== 0) initLanguage();
  }, [tvEnv]);

  return <LanguageContext.Provider value={{ langJson, initLanguage }}>{children}</LanguageContext.Provider>;
};

export const useLanguage = (): ILanguageContext => {
  const ctx = useContext(LanguageContext);
  if (ctx === undefined) {
    throw new Error("useTheme can only be used inside ThemeContext");
  }
  return ctx;
};
