import { useTheme } from "core/contexts/ThemeContext";
import Button from "components/common/DefaultBtn";
import { WelcomeContainer, WelcomeTitle, WelcomeWrapper } from "./index.style";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { tvEnvState } from "core/store/tvEnvState";

interface IProps {
  startHandler: () => void;
}

const CoverLayout = ({ startHandler }: IProps) => {
  const { SCENARIO } = useRecoilValue(tvEnvState);
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <WelcomeContainer $bgImage={theme.CoverLayout.image}>
      <WelcomeWrapper>
        <WelcomeTitle>
          <div>{t(`${SCENARIO}_cover_title`)}</div>
        </WelcomeTitle>
        <Button isDefault={true} handler={startHandler}>
          {t("common_start")}
        </Button>
      </WelcomeWrapper>
    </WelcomeContainer>
  );
};

export default CoverLayout;
