export enum STEP {
  // picture
  HDR = "hdr",
  MATRIX = "matrix",

  // oled
  BLACKDETAIL = "blackDetail",
  COLOR = "color",

  // sound
  OTSPLUS = "otsPlus",
  QSYMPHONY = "qSymphony",
  HOMECINEMA = "homeCinema",

  // common
  COVER = "cover",
  OUTRO = "outro",
  SMARTTHINGS = "smartThings",

  // 중국향
  AI_TV_UNVEILED = "AI_TV_UNVEILED",
  AVA = "AVA",
  AI_OPTIMIZATION = "AI_OPTIMIZATION",
}
