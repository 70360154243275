import { MainSoundPulse, PulseBox, PulseContainer } from "./index.style";
import { useTheme } from "core/contexts/ThemeContext";

const MainSoundBackground = () => {
  const { theme } = useTheme();

  return (
    <PulseContainer $bgLinear={theme.background.baseBg}>
      <PulseBox>
        <MainSoundPulse />
      </PulseBox>
    </PulseContainer>
  );
};

export default MainSoundBackground;
