import { GlobalThemeTypes } from "types/theme";
import { palette } from "./theme";
import * as MoreFeatureIcon from "./icons/moreFeature";

import PictureBg from "assets/background/bg_picture.png";

import WelcomePictureBg from "assets/welcome/bg_picture.png";

import * as StartLayoutIcon from "./icons/startLayout";
import { STEP } from "./enum/step";
import { SCENARIO_STEP } from "./enum/scenarioStep";

interface ScenarioThemeTypes {
  ai_qr: GlobalThemeTypes;
}

export const scenarioTheme: ScenarioThemeTypes = {
  ai_qr: {
    background: {
      baseBg: `
      background: linear-gradient(180deg, #B4401A 15.5%, #E62F24 52%, #A48639 100%);
    `,
      img: PictureBg,
    },
    headerTitle: "ai_qr_header",
    SNBList: [
      {
        text: "ai_qr_snb_ai_tv_unveiled",
        targetStep: { step: STEP.AI_TV_UNVEILED, scenarioStep: SCENARIO_STEP.EXPERIENCE },
      },
      {
        text: "ai_qr_snb_ava",
        targetStep: {
          step: STEP.AVA,
          scenarioStep: SCENARIO_STEP.EXPERIENCE,
        },
      },
      {
        text: "ai_qr_snb_ots_pro",
        targetStep: {
          step: STEP.OTSPLUS,
          scenarioStep: SCENARIO_STEP.EXPERIENCE,
        },
      },
      {
        text: "ai_qr_snb_ai_optimization",
        targetStep: {
          step: STEP.AI_OPTIMIZATION,
          scenarioStep: SCENARIO_STEP.EXPERIENCE,
        },
      },
      {
        text: "ai_qr_snb_morefeatures",
        targetStep: { step: STEP.OUTRO, scenarioStep: SCENARIO_STEP.START },
      },
    ],
    SliderList: {
      [STEP.AVA]: {
        left: { label: "ava_set_off", color: palette.grayScale.dark_60 },
        right: { label: "ava_set_on", color: palette.picture.yellow },
      },
      [STEP.AI_OPTIMIZATION]: {
        left: { label: "ai_qr_slider_left", color: palette.grayScale.dark_60 },
        right: { label: "ai_qr_slider_right", color: palette.picture.yellow },
      },
    },
    MoreFeatureList: [
      {
        title: "ai_qr_morefeature_1",
        icon: <MoreFeatureIcon.AIQR1 />,
      },
      {
        title: "ai_qr_morefeature_2",
        icon: <MoreFeatureIcon.AIQR2 />,
      },
      {
        title: "ai_qr_morefeature_3",
        icon: <MoreFeatureIcon.AIQR3 />,
      },
    ],
    StartLayouts: {
      [STEP.AI_TV_UNVEILED]: {
        subTitle: "ai_qr_start_ai_tv_unveiled_subtitle",
        mainTitle: "ai_qr_start_ai_tv_unveiled_title",
        explain: "ai_qr_start_ai_tv_unveiled_explain",
        icon: <StartLayoutIcon.AiUpscaling8k />,
      },
      [STEP.AVA]: {
        subTitle: "ai_qr_start_ava_subtitle",
        mainTitle: "ai_qr_start_ava_title",
        explain: "ai_qr_start_ava_explain",
        icon: <StartLayoutIcon.AVA color={palette.picture.yellow} />,
      },
      [STEP.OTSPLUS]: {
        subTitle: "ai_qr_start_ots_pro_subtitle",
        mainTitle: "ai_qr_start_ots_pro_title",
        icon: <StartLayoutIcon.AVA color={palette.picture.yellow} />,
      },
      [STEP.AI_OPTIMIZATION]: {
        subTitle: "ai_qr_start_ai_optimization_subtitle",
        mainTitle: "ai_qr_start_ai_optimization_title",
        explain: "ai_qr_start_ai_optimization_expain",
        icon: <StartLayoutIcon.AIOptimizationQR color={palette.picture.yellow} />,
      },
    },
    StepList: [STEP.COVER, STEP.AI_TV_UNVEILED, STEP.AVA, STEP.OTSPLUS, STEP.AI_OPTIMIZATION, STEP.OUTRO],
    ExperienceStepList: [SCENARIO_STEP.START, SCENARIO_STEP.SKIP, SCENARIO_STEP.BRIDGE, SCENARIO_STEP.EXPERIENCE],
    color: {
      mainColor: palette.picture.yellow,
      soundBtn: {
        btnBg: palette.picture.yellow,
        linear: {
          leftTop: "#C18F1E",
          rightDown: palette.picture.yellow,
        },
      },
      moreFeature: {
        default: {
          iconBoxShadow: {
            leftTop: "#C4872B",
            rightDown: "#09111C",
          },
          iconBgLinear: {
            leftTop: palette.picture.yellow,
            rightDown: "#9A741E",
          },
          bgLinear: {
            leftTop: "#222623",
            rightDown: palette.grayScale.dark_70,
          },
        },
        select: {
          iconBoxShadow: {
            leftTop: "#FFD953",
            rightDown: "#5A4A10",
          },
          iconBgLinear: {
            leftTop: "#222623",
            rightDown: "#222623",
          },
          bgLinear: {
            leftTop: palette.picture.yellow,
            rightDown: "#9A741E",
          },
        },
      },
      icon: {
        primary: palette.picture.yellow,
        background: palette.grayScale.dark_0,
        onBackground: palette.grayScale.dark_80,
        soundButton: palette.grayScale.dark_55,
      },
    },
    CoverLayout: {
      image: WelcomePictureBg,
      title: "ai_qr_cover",
    },
  },
};
