import Modal from "layout/Modal/Modal";
import { memo } from "react";
import { CompareModalProps } from "types/common";
import Slider from "components/common/Slider";
import { ExplainText, MainTitle, SubTitle, TitleContainer } from "constants/theme/globalStyles";
import { CompareContainer } from "./index.style";
import { useTranslation } from "react-i18next";

const CompareModal = ({
  isModalOpen,
  modalCloseHandler,
  startLayouts,
  closeButtonEnable = true,
  actions
}: CompareModalProps) => {
  const { subTitle, mainTitle, explain } = startLayouts;
  const isExplain = explain !== undefined;
  const { t } = useTranslation();

  return isModalOpen !== null ? (
    <Modal isModalOpen={isModalOpen} isCloseBtn={closeButtonEnable} isExperience modalCloseHandler={modalCloseHandler}>
      <CompareContainer $isExplain={isExplain}>
        <TitleContainer>
          <SubTitle>{t(subTitle)}</SubTitle>
          <MainTitle>{t(mainTitle)}</MainTitle>
          {isExplain && <ExplainText>{t(explain)}</ExplainText>}
        </TitleContainer>
        <Slider actions={actions} />
      </CompareContainer>
    </Modal>
  ) : null;
};

export default memo(CompareModal);
