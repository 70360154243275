import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAnimationControls } from "framer-motion";
import {
  CONTAINER_WIDTH,
  DivLine,
  DivWrap,
  Division,
  DivisionsWrap,
  Placeholder,
  PlaceholderDot,
  SLIDER_SIZE,
  SliderBgBorder,
  SliderContainer,
  SliderCursor,
  SliderLabel,
  SliderLabelContainer,
} from "./index.style";
import { useTheme } from "core/contexts/ThemeContext";
import { useSocket } from "core/contexts/SocketContext";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { stepState } from "core/store/stepState";
import { tvEnvState } from "core/store/tvEnvState";
import { SliderElement, SliderElementItem } from "types/common";

function Slider({ actions }: { actions?: (() => void)[] }) {
  const constraintsRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);

  const { theme } = useTheme();
  const { step } = useRecoilValue(stepState);
  const { SCENARIO } = useRecoilValue(tvEnvState);
  const { t } = useTranslation();
  const { experienceData } = useSocket();
  const options = useMemo(() => Object.values((theme.SliderList as SliderElement)[step]), [theme.SliderList, step]);
  const optionsLength = useMemo(() => options.length, [options]);
  const [currentIndex, setCurrentIndex] = useState(optionsLength === 3 ? 1 : 0);
  const [currentValue, setCurrentValue] = useState(optionsLength === 3 ? options[1] : options[0]);

  const [transitionEnabled, setTransitionEnabled] = useState(false);

  const animationControls = useAnimationControls();

  const DIVISION_LENGTH = Math.ceil((CONTAINER_WIDTH - SLIDER_SIZE) / (options.length - 1));

  const handleDragEnd = () => {
    if (sliderRef.current) {
      const translateXvalue = sliderRef.current.style.transform.match(/translateX\(([-0-9.]+)/i);
      if (translateXvalue) {
        const translateXValueRounded = Math.round(parseFloat(translateXvalue[1]));

        const division = Math.abs(Math.round(translateXValueRounded / DIVISION_LENGTH));

        animationControls.set({ x: division * DIVISION_LENGTH });

        setCurrentValue(options[division]);
        setTransitionEnabled(true);
      } else if (translateXvalue === null) {
        setCurrentValue(options[0]);
        setTransitionEnabled(true);
      }
    }
  };

  const handleDragStart = () => {
    setTransitionEnabled(false);
  };

  const handleDivisionPointerDown = (division: number, event?: React.PointerEvent<HTMLDivElement>) => {
    event?.stopPropagation();
    setTransitionEnabled(true);
    setCurrentValue(options[division]);
  };

  const getLabelBasedScenario = (idx: number, option: SliderElementItem) => {
    if (idx !== 2) {
      return option.label;
    }

    switch (SCENARIO) {
      case "oled":
        return step === "blackDetail" ? `${SCENARIO}_first_slider_right` : `${SCENARIO}_second_slider_right`;
      case "picture4k":
      case "picture8k":
        return step === "hdr"
          ? `${SCENARIO}_first_slider_right`
          : step === "matrix"
          ? `${SCENARIO}_second_slider_right`
          : "";
      default:
        return option.label;
    }
  };

  useEffect(() => {
    const currentValueIndex = options.findIndex((option) => option === currentValue);
    if (currentValueIndex >= 0) {
      setCurrentIndex(currentValueIndex);
      animationControls.start({
        x: DIVISION_LENGTH * currentValueIndex,
        transition: transitionEnabled ? { duration: 0.25 } : { duration: 0 },
        backgroundColor: currentValue?.color ? currentValue.color : undefined,
      });
    }
  }, [options, currentValue, DIVISION_LENGTH, transitionEnabled, animationControls]);

  useEffect(() => {
    if (optionsLength === 3) {
      if (actions?.[currentIndex]) {
        actions?.[currentIndex]();
        return;
      }
      experienceData(step, currentIndex === 1 ? "center" : currentIndex === 2 ? "right" : "left");
    } else {
      if (actions?.[currentIndex]) {
        actions?.[currentIndex]();
        return;
      }
      experienceData(step, currentIndex === 1 ? "right" : "left");
    }
  }, [actions, optionsLength, currentIndex]);

  return (
    <SliderContainer ref={constraintsRef}>
      <SliderBgBorder />
      <SliderCursor
        transition={{ ease: "easeOut", duration: 0.2 }}
        ref={sliderRef}
        drag={"x"}
        dragConstraints={constraintsRef}
        dragElastic={0}
        dragMomentum={false}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        animate={animationControls}
      />
      <DivisionsWrap>
        <DivLine />
        {options.map((_, index) => {
          return (
            <DivWrap
              key={index}
              style={{
                transform: `translate3d(calc(${index * DIVISION_LENGTH}px - 50%), -50%, 0)`,
              }}
            >
              <Division onPointerDown={(event) => handleDivisionPointerDown(index, event)} />
              <Placeholder onPointerDown={(event) => handleDivisionPointerDown(index, event)}>
                <PlaceholderDot />
              </Placeholder>
            </DivWrap>
          );
        })}
      </DivisionsWrap>
      <SliderLabelContainer>
        {options.map((option, index) => {
          return (
            <React.Fragment key={index}>
              <SliderLabel style={{ opacity: index === currentIndex ? 1 : 0.25 }}>
                {t(getLabelBasedScenario(index, option))}
              </SliderLabel>
              {optionsLength === 2 && index === 0 && <div style={{ flex: 1 }}></div>}
            </React.Fragment>
          );
        })}
      </SliderLabelContainer>
    </SliderContainer>
  );
}

export default Slider;
