import { palette } from "constants/theme";
import { typoOne400C, typoSharpSansBold, typoSharpSansRegular } from "constants/theme/fonts";
import styled, { css } from "styled-components";

// TITLE -----------------------------------
export const ExplainText = styled.div`
  ${typoOne400C}
  font-size: 14px;
  text-align: center;
  line-height: 160%;
  color: ${palette.grayScale.dark_10};

  opacity: 0.8;
`;

export const MainTitle = styled.div`
  margin-bottom: 16px;

  ${typoSharpSansBold}
  color: ${palette.grayScale.dark_0};
  font-size: 26px;
  line-height: 130%;
  text-align: center;
`;

export const SubTitle = styled.div`
  ${typoSharpSansRegular}
  font-size: 20px;
  line-height: 190%;
  color: ${(props) => props.theme.mainColor};
  text-align: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  height: calc(100% - 114px);
  padding-top: 114px;

  ${TitleContainer} {
    margin-bottom: 32px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 0 14px;
  gap: 16px;
`;

export const ItemCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;

  height: 100%;
  min-width: 100px;
`;

export const CardImageWrapper = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
`;
export const CardImage = styled.img<{ $isSelected: boolean }>`
  width: 100%;
  object-fit: cover;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;

  ${(props) =>
    props.$isSelected
      ? css`
          filter: blur(0.8rem);
        `
      : css`
          opacity: 0.61;
        `};
`;

export const CardCover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const CardTitle = styled.div<{ $active: boolean }>`
  width: 100%;

  text-align: center;
  ${typoSharpSansRegular}
  font-size:13px !important;

  ${({ $active }) =>
    $active
      ? css`
          position: absolute;
        `
      : css`
          color: ${palette.grayScale.dark_35};
        `}
`;

export const NowPlaying = styled.div`
  position: absolute;
  text-align: center;
  bottom: 14px;
  left: 6px;
  right: 6px;

  ${typoSharpSansBold}
  font-size: 11px;
`;
