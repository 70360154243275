import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { StartLayoutElement } from "types/common";
import Modal from "layout/Modal/Modal";
import * as S from "./index.style";
import { useSocket } from "core/contexts/SocketContext";
import { stepState } from "core/store/stepState";

import Img1 from "assets/image/AI_QR_mobile_1.png";
import Img2 from "assets/image/AI_QR_mobile_2.png";
import Img3 from "assets/image/AI_QR_mobile_3.png";

interface AITvUnveiledExperienceProps {
  startLayouts: StartLayoutElement;
  modalCloseHandler: () => void;
}

const list = [
  { image: Img1, title: "ai_tv_unveiled_demo1" },
  { image: Img2, title: "ai_tv_unveiled_demo2" },
  { image: Img3, title: "ai_tv_unveiled_demo3" },
];

export const AITvUnveiledExperience = ({ startLayouts, modalCloseHandler }: AITvUnveiledExperienceProps) => {
  const { experienceData } = useSocket();
  const { subTitle, mainTitle, explain } = startLayouts;
  const isExplain = explain !== undefined;
  const { t } = useTranslation();

  const step = useRecoilValue(stepState);
  const [curIndex, setCurIndex] = useState<number>(0);

  const changeIndex = (index: number) => {
    setCurIndex(index);
    experienceData(step.step, { aiTvUnveiled: index });
  };

  return (
    <Modal isModalOpen={true} isCloseBtn={true} isExperience modalCloseHandler={modalCloseHandler}>
      <S.Container>
        <S.TitleContainer>
          <S.SubTitle>{t(subTitle)}</S.SubTitle>
          <S.MainTitle>{t(mainTitle)}</S.MainTitle>
          {isExplain && <S.ExplainText>{t(explain)}</S.ExplainText>}
        </S.TitleContainer>
        <S.Wrapper>
          {list.map((elem, idx) => {
            const selected = idx === curIndex;
            return (
              <S.ItemCard key={idx}>
                <S.CardImageWrapper>
                  <S.CardImage
                    src={elem.image}
                    alt={`${idx}`}
                    $isSelected={selected}
                    onClick={() => changeIndex(idx)}
                  />
                  {selected && (
                    <S.CardCover>
                      <S.CardTitle $active={true}>{t(elem.title)}</S.CardTitle>
                      <S.NowPlaying>{t("ai_tv_unveiled_now_playing")}</S.NowPlaying>
                    </S.CardCover>
                  )}
                </S.CardImageWrapper>
                {!selected && <S.CardTitle $active={false}>{t(elem.title)}</S.CardTitle>}
              </S.ItemCard>
            );
          })}
        </S.Wrapper>
      </S.Container>
    </Modal>
  );
};
