import { zindex } from "constants/theme";
import { typoSharpSansBold, typoSharpSansRegular } from "constants/theme/fonts";
import styled from "styled-components";

export const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 112px;
`;

export const WelcomeTypo = styled.div`
  ${typoSharpSansRegular}
  font-size: 24px;
  text-align: center;
  line-height: 133%;
`;

export const WelcomeTitle = styled.div`
  ${typoSharpSansRegular}
  font-size: 28px;

  > div:nth-of-type(1) {
    line-height: 130%;
  }

  > div:nth-of-type(2) {
    ${typoSharpSansBold}
  }
`;

export const WelcomeContainer = styled.div<{
  $bgImage: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 112px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zindex.cover};

  width: 100%;
  height: 100%;

  background-image: url(${(props) => props.$bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  ${typoSharpSansRegular}
  font-size: 32px;
  text-align: center;
  line-height: normal;

  > button {
    min-width: 139px;
    width: fit-content;
    height: 45px;

    font-size: 14px;
    line-height: 14px; /* 100% */
    letter-spacing: 5px;
  }
`;
