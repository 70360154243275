import { useSocket } from "core/contexts/SocketContext";
import useTimeout from "core/hooks/useTimer";
import { stepState } from "core/store/stepState";
import CompareModal from "layout/Modal/CompareModal";
import StartLayout from "layout/StartLayout";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { StartLayoutElement } from "types/common";

export const AVAExpereince = ({ layoutInfo, nextExp }: { layoutInfo: StartLayoutElement; nextExp: () => void }) => {
  const [isNext, setIsNext] = useState(false);
  const { step } = useRecoilValue(stepState);
  const { setAsync } = useTimeout();
  const { experienceData } = useSocket();

  return !isNext ? (
    <CompareModal
      closeButtonEnable={false}
      isModalOpen={true}
      startLayouts={layoutInfo}
      actions={[
        () => {},
        async () => {
          experienceData(step, "on");
          await setAsync(500);
          setIsNext(true);
        },
      ]}
      modalCloseHandler={() => {}}
    />
  ) : (
    <StartLayout
      layoutInfo={layoutInfo}
      isSkip={true}
      timeBarEnable={false}
      buttonHandler={async () => {
        await setAsync(800);
        nextExp();
      }}
    />
  );
};
