import { memo } from "react";
import {
  CongratulationContainer,
  DefaultContainer,
  MoreFeaturesContainer,
  MoreFeaturesTitle,
  TopContainer,
} from "./index.style";
import MoreFeature from "components/common/MoreFeature";
import { ExplainText, MainTitle } from "constants/theme/globalStyles";
import Button from "components/common/DefaultBtn";
import { CongratulationLayoutProps } from "types/common";
import { useSocket } from "core/contexts/SocketContext";
import { useTranslation } from "react-i18next";
import useHeight from "core/hooks/useHeight";

const CongratulationLayout = ({ experience }: CongratulationLayoutProps) => {
  const { t } = useTranslation();
  const { isUnder } = useHeight();

  return (
    <>
      <CongratulationContainer>
        <TopContainer>
          <DefaultLayout experience={experience} />
        </TopContainer>
        <MoreFeaturesContainer $isUnder={isUnder}>
          <MoreFeaturesTitle $isUnder={isUnder}>{t("congratulation_morefeature_title")}</MoreFeaturesTitle>
          <MoreFeature />
        </MoreFeaturesContainer>
      </CongratulationContainer>
    </>
  );
};

export default memo(CongratulationLayout);

const DefaultLayout = ({ experience }: CongratulationLayoutProps) => {
  const { startAgain, smartthingsStartAgain } = useSocket();
  const { t } = useTranslation();
  return (
    <DefaultContainer>
      <MainTitle>{t("congratulation_default_title")}</MainTitle>
      <ExplainText>
        {t(`congratulation_default_${experience === "picture4k" ? "picture" : experience}_text`)}
      </ExplainText>
      <ExplainText>{t("congratulation_default_guide_play_again")}</ExplainText>
      <Button handler={experience === "smartthings" ? smartthingsStartAgain : startAgain}>
        {t("common_play_again")}
      </Button>
    </DefaultContainer>
  );
};
