import { Pulse, PulseBox, PulseContainer } from "./index.style";
import { useTheme } from "core/contexts/ThemeContext";

const SoundBackground = () => {
  const { theme } = useTheme();
  return (
    <PulseContainer $bgLinear={theme.background.baseBg}>
      <PulseBox>
        <Pulse />
      </PulseBox>
    </PulseContainer>
  );
};

export default SoundBackground;
