import { palette } from "constants/theme";
import { AiOptimizationBackgroundContainer } from "./index.style";
import { useTheme } from "core/contexts/ThemeContext";

const AiOptimizationBackground = () => {
  const { theme } = useTheme();

  return (
    <AiOptimizationBackgroundContainer $bgLinear={theme.background.baseBg}>
      <svg width="100%" height="100%">
        <defs>
          <pattern id="chainPattern" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <path d="M0,0 L20,20 M20,0 L0,20" stroke={palette.grayScale.dark_0+`0d`} strokeWidth="1" />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#chainPattern)" />
      </svg>
    </AiOptimizationBackgroundContainer>
  );
};

export default AiOptimizationBackground;
