import { MainTitle, SubTitle, TitleContainer } from "constants/theme/globalStyles";
import { memo, useEffect, useState } from "react";
import {
  BackgroundBar,
  BarContainer,
  IconContainer,
  MainBar,
  SoundMiddle,
  StartContainer,
  StartContent,
  TimerContainer,
  ValueBox,
} from "./index.style";
import Button from "components/common/DefaultBtn";
import { StartLayoutProps, TimerbarProps } from "types/common";
import { useTranslation } from "react-i18next";
import { useUrlQuery } from "core/hooks/useUrlQuery";
import { useRecoilValue } from "recoil";
import { stepState } from "core/store/stepState";
import { STEP } from "constants/enum/step";
import { AiUpscaling8k } from "constants/icons/startLayout";

const StartLayout = ({
  layoutInfo,
  isSkip = true,
  isExperience = false,
  timeBarEnable = true,
  time = -1,
  endTimerHandler,
  buttonHandler,
}: StartLayoutProps) => {
  const { t } = useTranslation();
  const { step } = useRecoilValue(stepState);
  const { is8K } = useUrlQuery(["is8K"] as const);

  return (
    <>
      <StartContainer>
        <StartContent $isSkip={isSkip}>
          <SoundMiddle>
            <TitleContainer>
              <SubTitle>{t(layoutInfo.subTitle)}</SubTitle>
              <MainTitle>{t(layoutInfo.mainTitle)}</MainTitle>
            </TitleContainer>
            <IconContainer>
              {step === STEP.AI_TV_UNVEILED && is8K === "true" ? <AiUpscaling8k /> : layoutInfo.icon}
            </IconContainer>
          </SoundMiddle>
          {!isExperience &&
            (isSkip ? (
              <Button handler={buttonHandler}>{t("common_skip")}</Button>
            ) : (
              <Button handler={buttonHandler}>{t("common_experience")}</Button>
            ))}
        </StartContent>
        {timeBarEnable && (
          <Timerbar
            length={time}
            endTimerHandler={() => {
              if (time >= 0) endTimerHandler && endTimerHandler();
            }}
          />
        )}
      </StartContainer>
    </>
  );
};

export default memo(StartLayout);

const Timerbar = ({ length, endTimerHandler }: TimerbarProps) => {
  const [nowSeconds, setNowSeconds] = useState(0);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    let currentSeconds = 0;
    const intervalBar = setInterval(() => {
      currentSeconds += 0.1;
      const calculatedPercentage = (currentSeconds / length) * 100;
      setPercentage(Math.min(calculatedPercentage, 100));

      if (currentSeconds >= length) {
        clearInterval(intervalBar);
      }
    }, 100);
    // const intervalBar = setInterval(() => {
    //   setPercentage((prev) => prev / length * 60 * 100);
    // }, 100);
    const interval = setInterval(() => {
      setNowSeconds((prev) => {
        if (prev === length) endTimerHandler && endTimerHandler();
        return prev >= length || prev < 0 ? prev : prev + 1;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
      clearInterval(intervalBar);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length]);

  const minutes: string = String(Math.floor(nowSeconds / 60)).padStart(2, "0");
  const seconds: string = String(nowSeconds % 60).padStart(2, "0");

  return (
    <TimerContainer>
      <BarContainer>
        <BackgroundBar />
        <MainBar style={{ width: `${percentage}%` }} />
      </BarContainer>
      <ValueBox>
        {minutes}:{seconds}
      </ValueBox>
    </TimerContainer>
  );
};
